import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ManageStudiesRepository extends BaseRepository {
    baseUrl = 'management/concrete/manage-studies';

    /**
     * Fetches the next code number
     *
     * @return {*}
     */
    fetchNextCodeNumber() {
        return this.httpClient.get(`${this.baseUrl}/next-code-number`);
    }
}
